@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/medium-editor/src/sass/medium-editor.scss";
@import "node_modules/medium-editor/src/sass/themes/default.scss";
@import "node_modules/medium-editor-insert-plugin/src/sass/medium-editor-insert-plugin.scss";

body {
  padding-top: 50px;
}

.table-nonfluid {
  width: auto;
}

.main img {
  margin: 20px 0;
}

.no-border {
  border: none;
}